export const styleConstants = {
    generalAppStyle: "font-medium tracking-[.4px]",
    hoverPurple: "rgb(175, 137, 238)",
    middlePurple: "rgb(151, 34, 255)",
    middleDarkPurple: "rgb(135, 39, 230)",
    darkPurple: "rgb(103, 49, 168)",
    darkPurpleTransparent: "rgb(103, 49, 168, 0.9)",
    secondaryPurple: "rgba(128, 0, 128)",
    paragraphGray: "#67666e",
    boxBackgroundGray: "#18161C",
    boxGridLayoutContainer: "flex w-full md:w-3/4 lg:w-full justify-between gap-y-8 flex-col lg:flex-row",
    boxGridLayout: "flex flex-col basis-[47%]",
    ccriBoxBackgroundColor: "bg-zinc-800",
    boxStyle: "bg-boxBackgroundGray px-4 py-4 rounded-xl",
    transitionStandard: "transition-all duration-500 ease-in-out",
    transitionSlow: "transition-all duration-1000",
    transitionFast: "transition-all duration-300",
    maxWidthTextPage: "max-w-4xl",
    mobileClasses: "visible sm:hidden",
    desktopClasses: "hidden sm:visible sm:block",
    mobileMdClasses: "visible md:hidden",
    desktopMdClasses: "hidden md:visible md:block",
    roundedBorder: "border rounded-xl shadow shadow-gray-900",
}