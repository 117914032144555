import React from 'react';
import { Paragraph } from '../Paragraph';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'


interface IBackendOfflineAlertProps {

}

export const OfflineAlert: React.FC<IBackendOfflineAlertProps> = (props) => {

  return (
    <div
      className='flex w-full flex-col items-center gap-y-4'
    >
      <ExclamationTriangleIcon
        className="text-paragraphGray w-12"
      />
      <Paragraph
        content={`Polygon Network Sustainability Dashboard has shut down.`}
        customStyle='!text-lg'
      />
    </div>
  )
};

